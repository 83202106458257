<template>
  <section class="section">
    <h2 class="title">
      {{ $t('route.sourceTypes') }}
      <div class="level">
        <div class="level-left subtitle">
          {{ $t('subtitle.sourceTypes') }}
        </div>
        <div class="level-right">
          <button class="button is-primary" @click.prevent="create">
            <octicon :icon="plus" /><span>{{ $t('newSourceType') }}</span>
          </button>
        </div>
      </div>
    </h2>
    <box>
      <data-table
        ref="table" :items="items"
        sort-by="_id" :theme="theme"
      >
        <data-column field="name" :label="$t('name')" width="20%" />
        <data-column field="description" :label="$t('description')" />
        <data-column field="type" :label="$t('type')" width="10%" />
        <data-column :label="$t('settings')" width="30%" :sortable="false">
          <template slot-scope="props">
            <tabs class="is-size-7" :theme="$_ui_theme_tabs">
              <tab :title="$t('delimiter')">
                <pre>{{ props.item.auto_detect ? '(auto)' : escapeNL(props.item.event_delimiter) }}</pre>
              </tab>
              <tab :title="$t('comment')">
                <pre>{{ props.item.auto_detect ? '(auto)' : props.item.comment }}</pre>
              </tab>
              <tab :title="$t('time')">
                <pre>{{ props.item.time_fill ? '(auto)' : props.item.time_field + `(${props.item.time_format})` }}</pre>
              </tab>
              <tab v-if="props.item.type === 'csv'" :title="$t('fields')">
                <pre>{{ renderFields(props.item) }}</pre>
              </tab>
              <tab v-if="props.item.type === 'custom'" :title="$t('regexp')">
                <pre>{{ escapeNL(props.item.regex_string) }}</pre>
              </tab>
            </tabs>
          </template>
        </data-column>
        <data-column
          :label="$t('actions')" class="has-text-centered" :sortable="false"
          width="15%"
        >
          <template slot-scope="props">
            <div class="has-text-centered">
              <button class="button is-text" @click.prevent="edit(props.item)">
                <octicon :icon="pencil" /> <span>{{ $t('edit') }}</span>
              </button>
              <button class="button is-text" @click.prevent="destroy(props.item)">
                <octicon :icon="x" /> <span>{{ $t('delete') }}</span>
              </button>
            </div>
          </template>
        </data-column>
      </data-table>
    </box>
    <modal v-if="item" :show.sync="modal">
      <box>
        <div slot="header">
          {{ item.name || $t('newSourceType') }}
        </div>

        <form @submit.prevent="submit">
          <div class="field">
            <label class="label">{{ $t('name') }}</label>
            <div class="control">
              <input
                v-model="item.name" class="input" type="text"
                required
              >
            </div>
          </div>

          <div class="field">
            <label class="label">{{ $t('description') }}</label>
            <div class="control">
              <input v-model="item.description" class="input" type="text">
            </div>
          </div>

          <div class="field">
            <label class="label">{{ $t('type') }}</label>
            <div class="control">
              <div class="select is-fullwidth">
                <select v-model="item.type" required>
                  <option disabled value="">
                    {{ $t('selectDefault') }}
                  </option>
                  <option v-for="type in types" :key="type" :value="type">
                    {{ type.toUpperCase() }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <template v-if="item.type === 'csv'">
            <div class="field">
              <label class="label">{{ $t('fields') }}</label>
              <div class="field is-grouped">
                <div class="control is-expanded">
                  <div class="field has-addons">
                    <p class="control is-expanded">
                      <input
                        v-model="field.name" class="input" type="text"
                        :placeholder="$t('name')"
                      >
                    </p>
                  </div>
                </div>
                <p class="control">
                  <a class="button is-info" @click.prevent="fieldAdd">{{ $t('add') }}</a>
                </p>
              </div>
              <div v-for="(f, index) in fields" :key="f.name" class="field is-grouped">
                <p class="control is-expanded">
                  <input
                    :value="f.name" class="input is-small is-readonly" type="text"
                    readonly
                  >
                </p>
                <p class="control">
                  <a class="button is-danger is-small" @click.prevent="fieldRemove(index)"><octicon :icon="x" /></a>
                </p>
              </div>
            </div>

            <div class="field">
              <label class="label">{{ $t('autoDetect') }}</label>
              <input
                id="auto_detect" v-model="item.auto_detect" type="checkbox"
                name="auto_detect" class="switch"
              >
              <label for="auto_detect">{{ item.auto_detect ? $t('enabled') : $t('disabled') }}</label>
            </div>
            <template v-if="!item.auto_detect">
              <div class="field">
                <label class="label">{{ $t('delimiter') }}</label>
                <div class="control">
                  <input
                    v-model="delimiter" class="input" type="text"
                  >
                </div>
              </div>
              <div class="field">
                <label class="label">{{ $t('field_delimiter') }}</label>
                <div class="control">
                  <input
                    v-model="item.field_delimiter" class="input" type="text"
                  >
                </div>
              </div>
              <div class="field">
                <label class="label">{{ $t('comment') }}</label>
                <div class="control">
                  <input
                    v-model="item.comment" class="input" type="text"
                  >
                </div>
              </div>
            </template>
          </template>
          <template v-else>
            <div class="field">
              <label class="label">{{ $t('delimiter') }}</label>
              <div class="control">
                <input
                  v-model="delimiter" class="input" type="text"
                >
              </div>
            </div>
            <div class="field">
              <label class="label">{{ $t('comment') }}</label>
              <div class="control">
                <input
                  v-model="item.comment" class="input" type="text"
                >
              </div>
            </div>
          </template>
          <template v-if="item.type === 'custom'">
            <div class="field">
              <label class="label">{{ $t('regexp') }}</label>
              <div class="control">
                <textarea v-model="item.regex_string" class="textarea" required />
              </div>
            </div>
          </template>

          <div class="field">
            <label class="label">{{ $t('time') }}</label>
            <input
              id="time_fill" v-model="item.time_fill" type="checkbox"
              name="time_fill" class="switch"
            >
            <label for="time_fill">{{ $t('autoFill') }}</label>
          </div>
          <template v-if="!item.time_fill">
            <div class="field is-grouped">
              <p class="control is-expanded">
                <input
                  v-model="item.time_field" class="input" type="text"
                  :placeholder="$t('field')"
                >
              </p>
              <p class="control is-expanded">
                <input
                  v-model="item.time_format" class="input" type="text"
                  :placeholder="$t('format')"
                >
              </p>
            </div>
          </template>
          <div slot="footer" class="field is-grouped is-grouped-right">
            <div class="control">
              <button type="submit" class="button is-link">
                {{ $t('save') }}
              </button>
            </div>
            <div class="control">
              <button type="button" class="button is-link is-light" @click.prevent="closeModal">
                {{ $t('cancel') }}
              </button>
            </div>
          </div>
        </form>
      </box>
    </modal>
  </section>
</template>
<script>
import { DataTable, DataColumn, Tabs, Tab, Modal } from '@cyradar/ui'
import { plus, x, pencil } from 'octicons-vue'

const uniq = (arr) => {
  return arr.filter((elem, pos, arr) => {
    return arr.indexOf(elem) === pos
  })
}

const types = ['json', 'csv', 'xml', 'syslog', 'custom']

export default {
  components: { DataTable, DataColumn, Tabs, Tab, Modal },
  data () {
    return {
      modal: false,
      item: null,
      field: {
        name: ''
      }
    }
  },
  computed: {
    types () {
      return types
    },
    delimiter: {
      get () {
        if (!this.item || !this.item.event_delimiter) {
          return ''
        }

        return this.escapeNL(this.item.event_delimiter)
      },
      set (val) {
        const raw = this.unescapeNL(val)
        if (!this.item) {
          return
        }

        this.item.event_delimiter = raw
      }
    },
    fields () {
      if (!this.item) {
        return []
      }

      const names = this.item.field_names.split(',')

      return names.filter(name => !!name).map((name, i) => {
        return {
          name
        }
      })
    },
    theme () {
      const theme = Object.assign({}, this.$_ui_theme_tables)
      theme['datatable__row--odd'] = ''
      return theme
    },
    plus () {
      return plus
    },
    x () {
      return x
    },
    pencil () {
      return pencil
    }
  },
  methods: {
    escapeNL (str) {
      return str.replace(/\n/g, '\\n')
    },
    unescapeNL (str) {
      return str.replace(/\\n/g, '\n')
    },
    renderFields (item) {
      const fields = item.field_names.split(',')
      return fields.map((f, i) => `${item.field_quote}${f}${item.field_quote}`).join(item.field_delimiter)
    },
    closeModal () {
      this.modal = false
    },
    fieldAdd () {
      const names = this.fields.map(x => x.name)
      if (names.length === uniq(names.concat(this.field.name)).length) {
        return
      }

      this.item.field_names = names.concat(this.field.name).join(',')
      this.field = {
        name: ''
      }
    },
    fieldRemove (idx) {
      const names = this.fields.map(x => x.name)
      names.splice(idx, 1)
      this.item.field_names = names.join(',')
    },
    submit () {
      if (this.item.id) {
        this.update()
        return
      }

      this.store()
    },
    create () {
      this.item = {
        type: '',
        field_names: '',
        event_delimiter: '\n',
        comment: '#',
        time_fill: true
      }

      this.modal = true
    },
    edit (item) {
      this.item = JSON.parse(JSON.stringify(item))
      this.modal = true
    },
    items (filter, order, pagination) {
      return this.$http.get('/api/v1/source-types', {
        params: {
          filter: filter.query,
          sort: order.by,
          order: order.order,
          page: pagination.page,
          limit: pagination.perPage
        }
      }).then(body => {
        if (!body || !body.data) {
          return {}
        }

        return body.data.data
      })
    },
    store () {
      return this.$http.post('/api/v1/source-types', this.item).then(body => {
        if (!body || !body.data) {
          return
        }

        this.closeModal()
        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })

        this.$refs.table.loadItems()
      })
    },
    update () {
      return this.$http.patch(`/api/v1/source-types/${this.item.id}`, this.item).then(body => {
        if (!body || !body.data) {
          return
        }

        this.closeModal()
        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })

        this.$refs.table.loadItems()
      })
    },
    destroy (item) {
      if (!window.confirm(this.$t('rus'))) {
        return
      }

      return this.$http.delete(`/api/v1/source-types/${item.id}`).then(body => {
        if (!body || !body.data) {
          return
        }

        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })

        this.$refs.table.loadItems()
      })
    }
  }
}
</script>
<style scoped>
pre {
  padding: 0.5em;
}
</style>
